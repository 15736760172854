import React, { useState } from "react"
import styles from "../../../styles/solutions.module.scss"
import cblockStyles from "../../../styles/commons/cblock.module.scss"
import FsLightbox from "fslightbox-react"
import Layout from "../../../components/layout/layout"
import StartDemo from "../../../components/start-demo/start-demo"
import SEO from "../../../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import withAppContext from "../../../contexts/app.context"
import UseCaseOverview from "../../../components/use-case-overview/use-case-overview"
import { Helper } from "../../../utils/helper"
import { ENV_CONSOLE_BASE_URL } from "../../../utils/secrets"
import WaspHeroAnim from "../../../components/animated-graphs/wasp-hero/wasp-hero-anim"
import TopFeatures from "../../../components/top-features/top-features"
import ComparisonTable from "../../../components/comparison-table/comparison-table"
import CascadeVideo from "../../../components/cascade-video/cascade-video"
import PlaySvg from "../../../components/svg/play"
import ArrowSvg from "../../../components/svg/arrow"

const SecurityAndPerformancePage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "solutions__web-app-security-and-performance" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
        metaTwImageName,
      },
    },
  } = queryResult

  const overviewLink = "https://player.vimeo.com/video/409046851?autoplay=1"
  const demoLink = "https://player.vimeo.com/video/420932805?autoplay=1"

  const [isVideoOpened, setVideoOpened] = useState(false)
  const [currentVideoUrl, setCurrentVideoUrl] = useState(overviewLink)

  const openSelectedVideo = () => {
    Helper.triggerGtagVideo(currentVideoUrl)
    setCurrentVideoUrl(overviewLink)
    setVideoOpened(!isVideoOpened)
  }

  const openDemoVideo = () => {
    Helper.triggerGtagVideo(currentVideoUrl)
    setCurrentVideoUrl(demoLink)
    setVideoOpened(!isVideoOpened)
  }

  const [selectedTopFeatIndex, setselectedTopFeatIndex] = useState(1)

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/solutions/network/web-app-security-and-performance"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />

      {/* Hero Block */}
      <section className={styles.header}>
        <div className={styles.header__content}>
          <div className="container">
            <div className="row">
              <div className={`col-lg-7`}>
                <h1 className={styles.header__title}>
                  Web App Security & Performance
                </h1>
                <p className={styles.header__description}>
                  Volterra’s advanced global network + VoltMesh provides L7
                  DDoS, WAF and API security with a globally distributed load
                  balancer — removing the need to deploy load balancers in every
                  cloud location and managing third-party DDoS + WAF + GLB
                  services.
                </p>
                <div className={styles.header__cta}>
                  <a
                    aria-label="Start for Free"
                    href={`${ENV_CONSOLE_BASE_URL}/signup/start`}
                    className={"btn btn-primary " + styles.header__btn}>
                    Start for Free
                  </a>
                  <button
                    onClick={openDemoVideo}
                    className={`btn btn-primary ml-4 ${styles.header__btn} ${styles.header__btnPlay}`}>
                    <PlaySvg />
                    Watch Demo
                  </button>
                </div>
                <div className={styles.header__doc}>
                  <p>
                    <a
                      aria-label="View demo documentation"
                      className={styles.header__link}
                      href="/docs/quick-start/web-app-security-performance">
                      View demo documentation
                      <ArrowSvg />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.header__imgwrapper}>
            <button
              className={styles.header__imgbtn}
              onClick={openSelectedVideo}>
              <PlaySvg />
            </button>
            <WaspHeroAnim />
          </div>
        </div>
      </section>

      {/* Benefits */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Protect Your Apps & APIs Across <br />
              Any Infrastructure
            </h2>
          </div>
          <div className={cblockStyles.cblock__body}>
            <TopFeatures
              props={{
                features: [
                  {
                    title: "Maximize security + reliability",
                    desc: `Increase service reliability by routing to multiple backends in public/private clouds. Improve security with multiple layers of controls — DDoS, WAF, bot detection and API protection. You can also enable our AI-based engine to automatically <a href="/products/voltmesh-automated-zero-trust-and-api-security" target="_blank">discover and control APIs.</a>`,
                    imgUrl: "/img/solutions/maximum-security-reliability.svg",
                    imgAlt: "Multi layered security illustration",
                  },
                  {
                    title: "Dramatic operational simplification",
                    desc:
                      "VoltMesh can be configured entirely in our network and as a distributed load balancer in your cloud clusters. This dramatically simplifies ops as policy changes get instantly deployed in your cloud and across our network.",
                    imgUrl: "/img/solutions/operational-simplification.svg",
                    imgAlt:
                      "Auto updating cloud and app infrastructure illustration",
                  },
                  {
                    title: "Improve customer engagement",
                    desc:
                      "Our global app delivery network reduces latency for your web apps and APIs -- helping convert and retain more customers. VoltMesh nodes in your cloud sites create redundant tunnels to our network for optimal and high performance routing.",
                    imgUrl: "/img/solutions/improve-customer-engagement.svg",
                    imgAlt: "Properly set up cloud deployment illustration",
                  },
                  {
                    title: "Consistent global experience",
                    desc:
                      "We help securely deliver your apps globally with the ability to selectively control or rate limit traffic from each geography. We terminate your users at our closest network location and apply security policies before the traffic can enter our network.",
                    imgUrl: "/img/solutions/consistent-global-experience.svg",
                    imgAlt: "Global apps showing same performance illustration",
                  },
                ],
              }}
            />
          </div>
        </div>
      </section>

      {/* Comparison Table */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Volterra vs. DDoS & CDN Providers
            </h2>
          </div>
          <div className={cblockStyles.cblock__body}>
            <ComparisonTable
              props={{
                cols: ["Features", "Traditional", "Volterra"],
                rows: [
                  {
                    name: "L3-L7 DDoS, WAF & bot mitigation",
                    cells: ["Included+", "Included"],
                  },
                  {
                    name: "Distributed load balancer",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "API auto-discovery & control",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "AI/ML for user behavior & Bot detection",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Advanced rate limiting",
                    cells: ["Included", "Included"],
                  },
                  {
                    name: "Unified analytics & observability",
                    cells: ["External", "Included"],
                  },
                  {
                    name: "Lifecycle management",
                    cells: ["DIY (cloud) + SaaS (network)", "SaaS"],
                  },
                ],
              }}
            />
          </div>
        </div>
      </section>

      {/* Solution Overview */}
      <UseCaseOverview
        props={{
          content: {
            description: `
              Volterra delivers <a href="/products/voltmesh-automated-zero-trust-and-api-security" target="_blank">web application and API protection</a> (WAAP) to protect against advanced DDoS and L4-L7+ attacks to customer applications deployed in the cloud or at edge sites. Our SaaS-based service delivers a single pane of glass for network, app, and API security to simplify operations. Customers also get a choice of deploying VoltMesh nodes in their app clusters in public/private cloud to <a href="/products/voltmesh-multi-cluster-load-balancer-and-api-gateway" target="_blank">distribute the load balancing</a> functionality across our network and cloud.  This can significantly improve web app performance by maintaining persistent and active-active connections across our global network.`,
            diagram: {
              alt: "Network & Security diagram",
              name: "security-and-performance-diagram.png",
              width: 840,
              md: {
                name: "security-and-performance-diagram-md.png",
                width: 760,
              },
              sm: {
                name: "security-and-performance-diagram-sm.png",
                width: 460,
              },
            },

            capabilities: {
              left: [
                "Volumetric DDoS defense",
                "API auto discovery + control",
                "Distributed load balancer",
                "WAFS, BOT, IPS & API security",
                "Advanced rate limiting",
                "Centralized observability",
              ],
            },
          },
        }}
      />

      {/* Ready in minutes */}
      <section
        className={`${cblockStyles.cblock} ${cblockStyles.cblock__bubbles__vmtlmr} ${cblockStyles.cblock__grey}`}>
        <div className={"container"}>
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Secure Web Apps & APIs within Minutes
            </h2>
          </div>

          <div className={cblockStyles.cblock__body}>
            <div className={"row " + styles.topfeatsslider}>
              <div className="col-lg-5">
                <div className={styles.topfeatsslider__items}>
                  {/* Item 1 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(1)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 1
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>1</span>
                      <h5>
                        Deploy VoltMesh in Your&nbsp;
                        <span>
                          Cloud
                          <img
                            className={`ml-2 ${styles.topfeatsslider__caret}`}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Fully automated provisioning of VoltMesh using the UI or
                        our vesctl CLI - deploy as a VM in your cloud VPC or add
                        as K8s Pod in your cluster
                      </p>
                      <a
                        href="/docs/how-to/site-management"
                        className={styles.topfeatsslider__link}
                        target="_blank"
                        aria-label="Step 1: Site Management">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 1 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462832779.sd.mp4?s=b7e953985ba701b04f9ae59d33c76056bd46e8be&profile_id=165",
                                  videoLength: "11000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462832845.sd.mp4?s=5fb7165d4d6bc5946dbda5e7dd925514c997c492&profile_id=165",
                                  videoLength: "16000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 2 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(2)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 2
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>2</span>
                      <h5>
                        Delegate&nbsp;
                        <span>
                          Domain
                          <img
                            className={styles.topfeatsslider__caret}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Delegate a domain or subdomain to manage creation of
                        public DNS entries and automated provisioning of TLS
                        certificates
                      </p>
                      <a
                        href="/docs/how-to/app-networking/domain-delegation"
                        className={styles.topfeatsslider__link}
                        target="_blank"
                        aria-label="Step 2: Delegate Domain">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 2 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462832738.sd.mp4?s=26bef13daaa2d714cbf9324831878eb30113c55a&profile_id=165",
                                  videoLength: "15000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462832737.sd.mp4?s=15dbb2984bf5de35d884301b745cb74e1f636991&profile_id=165",
                                  videoLength: "27000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 3 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(3)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 3
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>3</span>
                      <h5>
                        Configure Routes &&nbsp;
                        <span>
                          Security
                          <img
                            className={`ml-2 ${styles.topfeatsslider__caret}`}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Configure discovery of K8s or DNS services and implement
                        routing + security rules to direct incoming requests
                      </p>
                      <a
                        href="/docs/how-to/app-networking/http-load-balancer"
                        className={styles.topfeatsslider__link}
                        target="_blank"
                        aria-label="Step 3: Load Balancer">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 3 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462832824.sd.mp4?s=63ce44bcf138e2297cc63870839cc9929769a4c9&profile_id=165",
                                  videoLength: "37000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462832778.sd.mp4?s=d7cfbe0368f7c9ea93aefc2c4a71b39b39ec4245&profile_id=165",
                                  videoLength: "33000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 4 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(4)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 4
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>4</span>
                      <h5>
                        Send&nbsp;
                        <span>
                          Requests
                          <img
                            className={styles.topfeatsslider__caret}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Send requests to the specified service or APIs and let
                        VoltMesh handle routing and security for your apps
                      </p>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 4 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462832815.sd.mp4?s=e4de9e3ad61a71d6aeb2dd88cdfd733f6ef0b691&profile_id=165",
                                  videoLength: "33000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462832812.sd.mp4?s=b729b1d56d0c04f898595fcfbd669b8f876c41ed&profile_id=165",
                                  videoLength: "14000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className={styles.topfeatsslider__img}>
                  {selectedTopFeatIndex === 1 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462832779.sd.mp4?s=b7e953985ba701b04f9ae59d33c76056bd46e8be&profile_id=165",
                              videoLength: "11000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/462832845.sd.mp4?s=5fb7165d4d6bc5946dbda5e7dd925514c997c492&profile_id=165",
                              videoLength: "16000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 2 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462832738.sd.mp4?s=26bef13daaa2d714cbf9324831878eb30113c55a&profile_id=165",
                              videoLength: "15000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/462832737.sd.mp4?s=15dbb2984bf5de35d884301b745cb74e1f636991&profile_id=165",
                              videoLength: "27000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 3 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462832824.sd.mp4?s=63ce44bcf138e2297cc63870839cc9929769a4c9&profile_id=165",
                              videoLength: "37000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/462832778.sd.mp4?s=d7cfbe0368f7c9ea93aefc2c4a71b39b39ec4245&profile_id=165",
                              videoLength: "33000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 4 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462832815.sd.mp4?s=e4de9e3ad61a71d6aeb2dd88cdfd733f6ef0b691&profile_id=165",
                              videoLength: "33000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/462832812.sd.mp4?s=b729b1d56d0c04f898595fcfbd669b8f876c41ed&profile_id=165",
                              videoLength: "14000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <StartDemo
        props={{
          benefits: ["Web app security", "Distributed load balancer"],
        }}
      />

      <FsLightbox
        toggler={isVideoOpened}
        sources={[
          <iframe
            src={currentVideoUrl}
            width="1920px"
            height="1080px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />,
        ]}
        key="1"
      />
    </Layout>
  )
}

export default withAppContext(SecurityAndPerformancePage)
